/** @jsx jsx */
import { jsx, Text, useColorMode } from "theme-ui";
import React from "react";
import Navigation from "../components/navigation";
import Footer from "../components/footer";

import SEO from "../components/seo";
// import "../fonts/demo.css";

var { useEffect } = React;

const Index = (props) => {
	const [colorMode, setColorMode] = useColorMode();
	useEffect(() => {
		// localStorage.clear();
		// setColorMode("default");
	}, []);

	return (
		<>
			<div
				sx={{
					padding: [3, 5, 5],
					maxWidth: 3,
					margin: "auto",
					minHeight: "100vh",
					display: "flex",
					flexFlow: "column nowrap",
				}}
				className="Layout__Component"
			>
				<Navigation />
				{props.children}
				<Footer />
			</div>
		</>
	);
};

export default Index;
