import { gridStyles } from "../../styles/shared.styles";

export const pagePositionStyles = {
	marginTop: "auto",
};

export const containerStyles = {
	...gridStyles,
	marginTop: 4,
	// marginBottom: 8,
	alignItems: "start",
};

export const nameStyles = {
	gridColumn: "1 ",
	lineHeight: 0,
};

export const mainNavStyles = {
	// where -1 counts from the right
	gridColumn: [" 1 / -1", " 1 / -1", "3 / -1"],
	display: "flex",
	flexFlow: "row wrap",
	listStyleType: "none",
	padding: 0,
	marginTop: [4, 4, 0],
	alignContent: "start",
	fontWeight: "body",
	li: {
		lineHeight: 0,
		a: {
			marginRight: 4,
		},
	},
};

export const copyrightStyles = {
	visibility: ["hidden", "visible", "visible"],
	marginTop: [3, 0, 0],
	marginLeft: [0, "auto", "auto"],
	marginRight: 0,
	lineHeight: 0,
};
