/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import {
	pagePositionStyles,
	containerStyles,
	nameStyles,
	mainNavStyles,
	copyrightStyles,
} from "./index.styles";
import { Link } from "theme-ui";

import { socialLinks } from "../../utils/routes";

const Footer = () => {
	return (
		<footer sx={pagePositionStyles} role="contentinfo">
			<div sx={containerStyles}>
				<Text as="p" variant="caption" sx={nameStyles}>
					Jeremy Barnes
				</Text>
				<Text sx={mainNavStyles} variant="caption" as="ul">
					{socialLinks.map((social, i) => {
						return (
							<li key={i}>
								<Link
									variant="nav"
									href={social.path}
									target="_blank"
									rel="noreferrer"
								>
									{social.name}
								</Link>
							</li>
						);
					})}

					<li sx={copyrightStyles}>&copy; 2020 Jeremy S. Barnes</li>
				</Text>
			</div>
		</footer>
	);
};

export default Footer;
