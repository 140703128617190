import { gridStyles } from "../../styles/shared.styles";

export const containerStyles = {
	...gridStyles,
	// aligns left and right parts of nav
	// safari doesn't respect the baseline
	alignItems: ["center", "start", "start"],
};

export const nameStyles = {
	gridColumn: "1",
	lineHeight: 0,
	// this is b/c baseline alignment is buggy in safari so I have to do some custom alignment
	paddingTop: [0, 0, "3px"],
};

export const linkStyles = {
	gridColumn: ["1 / 1", "1 / span 1", "1 / span 3"],
	lineHeight: 0,
	paddingBottom: 1,
	borderBottom: (props) => `1px solid rgba(0,0,0,0)`,
	"&.active": {
		color: "primary",
		borderBottom: (props) => `1px solid ${props.colors.primary}`,
	},
};

export const mainNavStyles = {
	gridColumn: ["1 / -1", "1 / -1", "3 / -1"],
	// this shows and hides the nav depending on screen size
	display: ["none", "block", "block"],
	// marginLeft: ["inherit", "auto", 0],
	marginLeft: ["inherit", 0, 0],
	ul: {
		display: "flex",
		flexFlow: "row wrap",
		listStyleType: "none",
		paddingLeft: 0,
		marginTop: [0, 3, 0],
		alignContent: "start",
		li: {
			// lineHeight: 0,
			paddingRight: 4,
			marginBottom: 3,
			fontSize: 2,
			// this aligns dark mode toggle to the right
			":nth-last-of-type(2)": {
				marginRight: "auto",
			},
			":last-child": {
				paddingRight: 0,
			},
		},
	},
};
