export const containerStyles = {
	display: ["block", "none", "none"],
	gridColumn: "-2",
	justifySelf: "end",
	zIndex: 10,
};

export const hamburgerStyles = {
	margin: 0,
	padding: 0,
	listStyleType: "none",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "40px",
	height: "40px",
	cursor: "pointer",
	position: "relative",
	zIndex: 13,
	transition: "all .2s ease-in-out",
	border: 0,
	background: 0,
	div: {
		position: "relative",
		width: "30px",
		height: "4px",
		backgroundColor: "grays.100",
		borderRadius: "6px",
		transition: "all .25s ease-in-out",
		"::before": {
			content: '""',
			position: "absolute",
			left: 0,
			width: "30px",
			height: "4px",
			borderRadius: "6px",
			transition: "all .25s ease-in-out",
			backgroundColor: "grays.100",
		},
		"::after": {
			content: '""',
			position: "absolute",
			left: 0,
			width: "30px",
			height: "4px",
			borderRadius: "6px",
			transition: "all .2s ease-in-out",
			backgroundColor: "grays.100",
		},
	},
	".closed": {
		transform: "translateX(-0px)",
		"::before": {
			transform: "translateY(-8px)",
		},
		"::after": {
			transform: "translateY(8px)",
		},
	},

	".open": {
		background: "transparent",
		"::before": {
			transform: "rotate(45deg) translateY(0px)",
		},
		"::after": {
			transform: "rotate(-45deg) translateY(0px)",
		},
	},
};

export const navWrapperStyles = {
	backgroundColor: "background",
	position: "absolute",
	opacity: 0.95,
	visibility: "visible",
	"&.open": {
		opacity: 0.95,
		visibility: "visible",
		transition: "all .2s ease-in-out",
	},
	"&.closed": {
		opacity: 0,
		visibility: "hidden",
		transition: "all .2s ease-in-out",
	},
	height: "100vh",
	width: "100%",
	top: 0,
	left: 0,
	paddingTop: 0,
	paddingLeft: 5,
	margin: 0,
	zIndex: 10,
	display: "flex",
	flexFlow: "column wrap",
	justifyContent: "center",
	// alignItems: "center",
	listStyleType: "none",
	li: {
		marginBottom: 2,
	},
	a: {
		"&.active": {
			color: "primary",
		},
	},
};

export const navUIWrapperStyles = {
	display: "flex",
	flexFlow: "row nowrap",
	alignItems: "center",
	cursor: "pointer",
};
