export default {
	partOne: {
		url: "/blog/creating-modular-layouts-in-react-part-1",
	},
	github: {
		url:
			"https://github.com/JeremyTheModernist/tutorials-modular-layouts-in-react",
	},
	portfolio: {
		url: "https://jeremybarnesdesign.com/tressolutions",
	},
	pexels: {
		url: "https://www.pexels.com/",
	},
	twitter: {
		url: "https://twitter.com/JeremyStuBarnes",
	},
};
