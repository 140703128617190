// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notes-js": () => import("./../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-oss-js": () => import("./../src/pages/oss.js" /* webpackChunkName: "component---src-pages-oss-js" */),
  "component---src-pages-reading-js": () => import("./../src/pages/reading.js" /* webpackChunkName: "component---src-pages-reading-js" */),
  "component---src-templates-single-post-index-js": () => import("./../src/templates/single-post/index.js" /* webpackChunkName: "component---src-templates-single-post-index-js" */)
}

