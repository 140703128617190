export const desktopContainerStyles = {
	display: "flex",
	flexFlow: "row nowrap",
	cursor: "pointer",
	background: 0,
	border: 0,
};

export const desktopTextStyles = {
	alignItems: "center",
	color: "primary",
	textTransform: "capitalize",
	marginRight: 3,
};
