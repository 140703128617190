export const routes = [
	{
		path: "/blog",
		name: "Blog",
	},
	{
		path: "/notes",
		name: "Notes",
	},
	{
		path: "/oss",
		name: "OSS",
	},
	{
		path: "/reading",
		name: "Reading",
	},
	{
		path: "/about",
		name: "About",
	},
	{
		path: "https://jeremybarnesdesign.com/",
		name: "Design",
	},
];

export const socialLinks = [
	{
		path: "https://twitter.com/JeremyStuBarnes",
		name: "Twitter",
	},
	{
		path: "https://github.com/JeremyTheModernist",
		name: "Github",
	},
	{
		path: "https://www.instagram.com/_jeremybarnes/?hl=en",
		name: "Instagram",
	},
];
