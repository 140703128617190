// src/gatsby-plugin-theme-ui/components.js
import Prism from "@theme-ui/prism";
import React from "react";

// export default {
// 	pre: (props) => <pre tabIndex={0}>{props.children}</pre>,
// 	code: (props) => <code tabIndex={0}>{Prism(props)}</code>,
// };

export default {
	pre: (props) => props.children,
	code: Prism,
};
