export const gridStyles = {
	maxWidth: `100%`,
	// margin: "auto",
	marginTop: 0,
	marginBottom: 0,
	display: "grid",
	gridTemplateColumns: "repeat(auto-fill, minmax(128px, 1fr))",
	// gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
};

export const bodyColumn = {
	// span the full width at mobile size
	gridColumn: ["1 / -1", "1 / -1", "3 / span 5"],
	// gridColumn: "1 / -1",
	// width: "50%",
	// margin: "auto",
};

export const imageColumn = {
	gridColumn: ["-1 / 1", "1 / -1", "2 / span 7"],
};

export const readingColumn = {
	gridColumn: ["-1 / 1", "1 / -1", "3 / span 5"],
};

export const listStyles = {
	listStyleType: "none",
	padding: 0,
	margin: 0,
};
