/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import React from "react";
import {
	containerStyles,
	navUIWrapperStyles,
	hamburgerStyles,
	navWrapperStyles,
} from "./Index.styles";
import { Link } from "gatsby";

import { MobileThemeToggle } from "../themetoggle/Index.js";

import { routes } from "../../../utils/routes";

var { useState } = React;

const MobileNav = () => {
	var [nav, toggleNav] = useState(false);

	function showHideNav() {
		toggleNav((curState) => !curState);
		nav ? showOverflow() : hideOverflow();
	}
	function hideOverflow() {
		// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
		document.body.style.position = "fixed";
		document.body.style.overflowY = "hidden";
	}
	function showOverflow() {
		document.body.style.position = "";
		// document.body.style.top = "";
		document.body.style.overflowY = "auto";
	}
	return (
		<div sx={containerStyles}>
			<div sx={navUIWrapperStyles}>
				<MobileThemeToggle />
				<button
					sx={hamburgerStyles}
					onClick={showHideNav}
					aria-expanded={nav}
					aria-label={nav ? "close the menu" : "open the menu"}
				>
					<div className={nav ? "open" : "closed"}></div>
				</button>
			</div>
			<ul
				sx={navWrapperStyles}
				className={nav ? "open" : "closed"}
				onClick={showHideNav}
			>
				{routes.map((route, i) => {
					if (!route.name.includes("Design")) {
						return <MobileNav.internalLink route={route} key={i} />;
					}
					return <MobileNav.externalLink route={route} key={i} />;
				})}
			</ul>
		</div>
	);
};

export default MobileNav;

MobileNav.internalLink = ({ route }) => {
	return (
		<Text as="li" variant="mobileNav">
			<Link
				sx={(props) => {
					return { ...props.links.nav, fontWeight: "bold" };
				}}
				activeClassName="active"
				partiallyActive={true}
				to={`${route.path}`}
			>
				{route.name}
			</Link>
		</Text>
	);
};

MobileNav.externalLink = ({ route }) => {
	return (
		<Text as="li" variant="mobileNav">
			<a
				sx={(props) => {
					return { ...props.links.nav, fontWeight: "bold" };
				}}
				href={`${route.path}`}
				target="_blank"
				rel="noreferrer"
			>
				{route.name}
			</a>
		</Text>
	);
};
