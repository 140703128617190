import prism from "@theme-ui/prism/presets/theme-ui";
import "../fonts/demo.css";

const theme = {
	fonts: {
		body:
			'inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
		bodySerif: `LeituraNews, georgia`,
		code: `SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospacepre, code`,
	},
	fontSizes: [10, 13, 16, 19, 21, 24, 34, 48, 54, 63, 72],
	lineHeights: [1.1, 1.25, 1.5, 1.6, 1.7],
	letterSpacing: ["-0.1rem", "-0.025rem", "0rem", ".025rem", "0.1rem"],
	fontWeights: {
		quote: 300,
		body: 400,
		medium: 500,
		semibold: 600,
		bold: 700,
		heading: 700,
	},
	text: {
		display: {
			fontSize: [6, 7, 7],
			fontWeight: "heading",
			letterSpacing: 0,
			lineHeight: 0,
		},
		listTitle: {
			fontSize: 3,
			fontWeight: "bold",
			lineHeight: 1,
		},
		bodyTitle: {
			fontSize: 5,
			fontWeight: "semibold",
			lineHeight: 1,
			letterSpacing: 1,
		},
		blockquote: {
			fontSize: 5,
			fontWeight: "quote",
			lineHeight: 2,
			paddingLeft: 4,
			paddingTop: 2,
			paddingBottom: 2,
			fontFamily: "body",
		},
		body: {
			fontSize: ["18px", 3, "18px"],
			fontWeight: "body",
			lineHeight: 2,
		},
		articleBody: {
			fontFamily: "bodySerif",
			// fontStyle: "normal",
			fontSize: ["18px", 3, 3],
			fontWeight: "body",
			lineHeight: [2, 3, 3],
		},
		caption: {
			fontSize: 2,
			fontWeight: "body",
			lineHeight: [2, 3, 3],
		},
		nav: {
			fontSize: 2,
			fontWeight: 600,
			lineHeight: 2,
		},
		mobileNav: {
			fontSize: 6,
			fontWeight: 600,
			lineHeight: 2,
		},
	},
	layout: {
		body: {
			maxWidth: 1,
			padding: 3,
		},
	},
	links: {
		primary: {
			fontSize: ["18px", 3, 3],
			letterSpacing: 2,
			fontWeight: "medium",
			fontFamily: "body",
			color: "primary",
			transition: ".3s ease-in-out",
			borderBottom: (props) => [
				"none",
				"none",
				`1px solid ${props.colors.primaryTint3}`,
			],
			":hover": {
				transition: ".3s ease-in-out",
				borderBottom: (props) => [
					"none",
					"none",
					`1px solid ${props.colors.primary}`,
				],
			},
		},
		gray: {
			fontSize: ["18px", 3, 3],
			color: "grays.50",
			transition: ".3s ease-in-out",
			borderBottom: `1px solid #AAA3CC`,
			":hover": {
				color: "text",
				transition: ".3s ease-in-out",
				borderBottom: (props) => `1px solid ${props.colors.text}`,
			},
		},
		black: {
			fontSize: ["18px", 3, 3],
			fontWeight: "body",
			color: "text",
			transition: ".3s ease-in-out",
			borderBottom: [`none`, `none`, `1px solid #AAA3CC`],
			":hover": {
				color: "primary",
				transition: ".3s ease-in-out",
				borderBottom: (props) => [
					"none",
					"none",
					`1px solid ${props.colors.primary}`,
				],
			},
		},
		nav: {
			color: "text",
			transition: ".3s ease-in-out",
			":hover": {
				transition: ".3s ease-in-out",
				color: "primary",
			},
		},
		CTA: {
			color: "primary",
			fontWeight: "semibold",
			border: "none",
			transition: ".3s ease-in-out",
			":hover": {
				border: "none",
				color: "text",
				transition: ".3s ease-in-out",
			},
		},
	},
	styles: {
		root: {
			fontFamily: "body",
			backgroundColor: "background",
			">*": {
				margin: 0,
				padding: 0,
			},
			h1: {
				fontWeight: "bold",
			},
			a: {
				textDecoration: "none",
				padding: 0,
			},
			blockquote: {
				margin: 0,
				padding: 0,
			},
			p: {
				margin: 0,
				padding: 0,
				code: {
					fontSize: 2,
					fontFamily: "code",
					// color: "primary",
					// backgroundColor: "rgba(0,0,0,.06)",
					backgroundColor: "primaryTint2",
					padding: "3px",
					borderRadius: 1,
				},
			},
			li: {
				code: {
					fontSize: 2,
					fontFamily: "code",
					// color: "primary",
					// backgroundColor: "rgba(0,0,0,.06)",
					backgroundColor: "primaryTint2",
					padding: "3px",
					borderRadius: 1,
				},
			},
		},
		ul: {
			display: "flex",
			flexFlow: "column",
			listStyleType: "none",
			marginLeft: 0,
			paddingLeft: 0,
			marginTop: 4,
			">*": {
				marginBottom: 3,
			},
			marginBottom: 4,
		},
		// have to pass the code element so it will style correctly
		code: {
			fontFamily: "code",
		},
		pre: {
			...prism,
			fontSize: 2,
			fontFamily: "code",
			backgroundColor: "grays.10",
			paddingTop: 3,
			paddingBottom: 3,
			marginTop: 4,
			marginBottom: 3,
			borderRadius: 1,
			overflow: "auto",
			// style my code highlights
			".highlight": {
				background: (props) => props.colors.codeHighlight,
				borderLeft: (props) => `3px solid ${props.colors.accent}`,
				overflow: "visible",
			},
			".token-line": {
				lineHeight: [2, 4, 4],
				paddingLeft: 3,
				paddingRight: 3,
			},
			// highlight: {
			// 	background: "yellow",
			// },
		},
	},
	colors: {
		text: "rgba(12,12,13,1)",
		// background: "#F6F5FC",
		background: "#F8F7FC",
		primary: "#6018F2",
		primaryTint: "rgba(96, 24, 242, 0.1)",
		primaryTint2: "rgba(96, 24, 242, 0.13)",
		primaryTint3: "rgba(96, 24, 242, 0.3)",
		tile: "white",
		// secondary: "#00994D",
		secondary: "#0063C7",
		accent: "#C70074",
		gray: "rgba(12,12,13,.65)",
		codeHighlight: "rgba(12,12,13,.022)",
		grays: {
			0: "#FBFAFF",
			10: "rgba(12,12,13,.05)",
			20: "rgba(12,12,13,.25)",
			30: "rgba(12,12,13,.35)",
			40: "rgba(12,12,13,.45)",
			50: "rgba(12,12,13,.55)",
			60: "rgba(12,12,13,.65)",
			70: "rgba(12,12,13,.75)",
			80: "rgba(12,12,13,.85)",
			90: "rgba(12,12,13,.95)",
			100: "rgba(12,12,13,1)",
		},
		modes: {
			dark: {
				text: "rgba(255,255,255,.90)",
				// background: "#17161A",
				background: "#1C1B1F",
				primary: "#CF9EFF",
				primaryTint: "rgba(178,102,255,.22)",
				primaryTint2: "rgba(178,102,255,.27)",
				primaryTint3: "rgba(178,102,255,.5)",
				secondary: "#66D9FF",
				accent: "#5CE59B",
				tile: "rgba(255,255,255,.09)",
				gray: "rgba(255,255,255,.55)",
				codeHighlight: "rgba(255,255,255,.022)",
				grays: {
					0: "#FBFAFF",
					10: "rgba(255,255,255,.05)",
					20: "rgba(255,255,255,.25)",
					30: "rgba(255,255,255,.35)",
					40: "rgba(255,255,255,.45)",
					50: "rgba(255,255,255,.55)",
					60: "rgba(255,255,255,.65)",
					70: "rgba(255,255,255,.75)",
					80: "rgba(255,255,255,.85)",
					90: "rgba(255,255,255,.95)",
					100: "rgba(255,255,255,1)",
				},
			},
			light: {
				text: "rgba(12,12,13,1)",
				// background: "#F6F5FC",
				background: "#F8F7FC",
				primary: "#6018F2",
				primaryTint: "rgba(96, 24, 242, 0.1)",
				primaryTint2: "rgba(96, 24, 242, 0.13)",
				primaryTint3: "rgba(96, 24, 242, 0.3)",
				tile: "white",
				// secondary: "#00994D",
				secondary: "#0063C7",
				accent: "#C70074",
				gray: "rgba(12,12,13,.65)",
				codeHighlight: "rgba(12,12,13,.022)",
				grays: {
					0: "#FBFAFF",
					10: "rgba(12,12,13,.05)",
					20: "rgba(12,12,13,.25)",
					30: "rgba(12,12,13,.35)",
					40: "rgba(12,12,13,.45)",
					50: "rgba(12,12,13,.55)",
					60: "rgba(12,12,13,.65)",
					70: "rgba(12,12,13,.75)",
					80: "rgba(12,12,13,.85)",
					90: "rgba(12,12,13,.95)",
					100: "rgba(12,12,13,1)",
				},
			},
		},
	},
	sizes: [400, 600, 800, 1280, 1440, 1536],
	space: [0, 5, 10, 15, 30, 45, 60, 75, 90, 120],
	radii: [0, 4, 8],
	// shadows: {
	// 	10: `0px 4px 8px rgba(12,12,13,.05)`,
	// 	20: `0px 8px 12px rgba(12,12,13,.15)`,
	// 	30: `0px 12px 16px rgba(12,12,13,.15)`,
	// 	40: `0px 16px 20px rgba(12,12,13,.15)`,
	// 	50: `0px 20px 24px rgba(12,12,13,.25)`,
	// 	60: `0px 24px 28px rgba(12,12,13,.15)`,
	// 	100: `0px 8px 12px rgba(12,12,13,.95)`,
	// },
	shadows: {
		10: `0px 4px 8px rgba(12,12,13,.05)`,
		20: `0px 8px 12px rgba(12,12,13,.15)`,
		30: `0px 12px 16px rgba(12,12,13,.15)`,
		40: `0px 16px 20px rgba(12,12,13,.15)`,
		50: `0px 20px 40px rgba(12,12,13,.2)`,
		60: `0px 30px 60px rgba(12,12,13,.2)`,
		100: `0px 8px 12px rgba(12,12,13,.95)`,
	},
	borders: {
		light: `1px solid rgba(12,12,13,.1)`,
	},
};

export default theme;
